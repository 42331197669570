









































import { patch_device_locations, post_device_locations } from "@/api";
import { VuetifyForm } from "@/shared/types";
import { required } from "@/shared/validation";
import { Notif } from "@/store";
import {
  Component,
  Emit,
  Prop,
  PropSync,
  Vue,
  Watch,
} from "vue-property-decorator";

type formDevLocal = {
  uuid: string;
  name: string;
  telegramChatId: string | null;
  note: string | null;
};

@Component({ name: "device-location-add" })
export default class DevLocalAdd extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: boolean;
  @PropSync("formEdit", { type: Object }) form_edit!: formDevLocal | null;
  // @Prop({ required: false }) readonly formEdit!: formDevLocal | null;

  required = required;

  /* Variables */

  formData = {
    name: "",
    telegramChatId: "" as string | null,
    note: "" as string | null,
  };

  /* Computed */
  /* Methods */

  @Emit("submit")
  async submit(): Promise<void> {
    try {
      if (!(this.$refs.formData as VuetifyForm).validate) return;
      if (!this.form_edit) {
        await post_device_locations(this.formData);
        Notif.notif_success("Yeay!!!");
      } else {
        await patch_device_locations(this.form_edit.uuid, this.formData);
        Notif.notif_success("Device Location Editted.");
      }
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  @Emit("input")
  close(): null {
    (this.$refs.formData as VuetifyForm).reset();
    this.form_edit = null;
    return null;
  }

  /* Life-cycle Methods */

  @Watch("formEdit")
  onFormEditChange(now: formDevLocal): void {
    if (!now) return;
    const { name, telegramChatId, note } = now;
    this.formData = { name, telegramChatId, note };
  }
}
